import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import Container from '../components/container';
import '../styles/contact.scss';

function ContactPage() {
  return (
    <Container>
      <Header />
      <main>
        <div className="contact-page">
          <h1 className='fs-primary-heading'>Contact Us</h1>
          <p>We value the opportunity to talk about your next project and how BHA Construction can help.</p>
          <div className="contact-details">
            <div className="contact-info">
              <h2>Phone Number</h2>
              <p>​(053) 913 6714</p>
            </div>
            <div className="contact-info">
              <h2>Location</h2>
              <p>BHA Construction Ltd, Kilbride, The Ballagh, Co. Wexford, Y21 H728</p>
            </div>

            <div className="contact-info">
              <h2>Email</h2>
              <p>​info@bhaconstruction.com</p>
            </div>
          </div>
          <div className="map-container">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9718.78637520511!2d-6.4400599!3d52.484629!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486807530d01b143%3A0xa4e8cddb7de0b116!2sBHA%20Construction%20Ltd.!5e0!3m2!1sen!2sie!4v1685967244865!5m2!1sen!2sie" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


          </div>
        </div>
      </main>
      <Footer />
    </Container>
  );
}

export default ContactPage;
